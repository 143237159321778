import React, { useState, useEffect } from 'react'
import { isFunction, isEqual, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Divider, Box, Paper, Typography, Button, SvgIcon } from '@material-ui/core'
import { Edit as EditIcon, Save as SaveIcon, PlusCircle as PlusCircleIcon } from 'react-feather'
import { useSnackbar } from 'notistack'

import { usePrevious } from 'hooks/usePrevious'

const SectionCard = ({
  title,
  variant,
  children,
  p,
  mt,
  mb,
  contentPadding,
  renderActions,
  showEdit,
  showAdd,
  showCustomButton,
  customButtonLabel,
  onCustomButtonAction,
  defaultValues,
  onEditModeChange,
  onShowModal,
  onSave,
  onSubmit,
  saveAction,
  originalEntity,
  onOpenModal,
  extraContent,
  ...props
}) => {
  const { t } = useTranslation()
  const { control, handleSubmit, reset, formState, watch, errors } = useForm({
    defaultValues,
  })
  const prevDefaultValues = usePrevious(defaultValues)
  const { enqueueSnackbar } = useSnackbar()

  const [isEditMode, setIsEditMode] = useState(false)
  const [isDirty, setIsDirty] = useState(formState.isDirty)

  const handleSetEditMode = (e) => setIsEditMode(true)

  const handleCancelEditMode = (e) => setIsEditMode(false)
  const handleSave = (values) => {
    enqueueSnackbar(t('Saving changes...'), { variant: 'info' })
    onSave &&
      onSave(values)
        .then(({ meta, error }) => {
          const { params } = meta.arg
          if (!isEmpty(params) && !error?.message) {
            setIsEditMode(false)
            reset(params)
            enqueueSnackbar(t('Saved Successfully!'), { variant: 'success' })
          }
          if (error?.message) enqueueSnackbar(error?.message, { variant: 'error' })
        })
        .catch((error) => {
          enqueueSnackbar(error?.message ?? t('There was an error while saving...'), { variant: 'error' })
        })
    onSubmit &&
      onSubmit(values)
        .then(({ values, error }) => {
          if (!isEmpty(values) && !error?.message) {
            setIsEditMode(false)
            reset(values)
            enqueueSnackbar(t('Saved Successfully!'), { variant: 'success' })
          }
          if (error?.message) enqueueSnackbar(error?.message, { variant: 'error' })
        })
        .catch((error) => {
          enqueueSnackbar(error?.message ?? t('There was an error while saving...'), { variant: 'error' })
        })
  }

  useEffect(() => {
    onEditModeChange && onEditModeChange(isEditMode)
  }, [isEditMode, onEditModeChange])

  useEffect(() => {
    setIsDirty(formState.isDirty)
  }, [formState.isDirty])

  useEffect(() => {
    ;(!isEqual(prevDefaultValues, defaultValues) || !isEditMode) && reset(defaultValues)
  }, [prevDefaultValues, defaultValues, reset, isEditMode])

  const renderEditModeActions = () => (
    <Box display="flex">
      <Box mr={p}>
        <Button variant="contained" size="small" onClick={handleCancelEditMode}>
          {t('actions.cancel')}
        </Button>
      </Box>
      <Box mr={p}>
        <Button variant="contained" size="small" onClick={() => reset(defaultValues)} disabled={!isDirty}>
          {t('actions.reset')}
        </Button>
      </Box>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        type="submit"
        disabled={!isDirty}
        startIcon={
          <SvgIcon fontSize="small">
            <SaveIcon />
          </SvgIcon>
        }
      >
        {t('actions.save')}
      </Button>
    </Box>
  )

  const renderContent = () => (
    <Paper>
      {title && (
        <>
          <Box p={p} display="flex" justifyContent="space-between">
            <Typography variant={variant} color="textPrimary">
              {title}
            </Typography>
            {renderActions && renderActions()}
            {showEdit &&
              (isEditMode ? (
                renderEditModeActions()
              ) : (
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSetEditMode}
                    startIcon={
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    }
                  >
                    {t('actions.edit')}
                  </Button>
                </Box>
              ))}
            {showAdd && (
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusCircleIcon />
                    </SvgIcon>
                  }
                  onClick={onShowModal}
                >
                  {t('actions.add')}
                </Button>
              </Box>
            )}
            {showCustomButton && (
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusCircleIcon />
                    </SvgIcon>
                  }
                  onClick={onCustomButtonAction}
                >
                  {customButtonLabel}
                </Button>
              </Box>
            )}
          </Box>

          <Divider />
        </>
      )}

      {!!children && (
        <Box p={contentPadding > -1 ? contentPadding : p} pb={contentPadding > -1 ? contentPadding : p + 1}>
          {isFunction(children)
            ? children({ control, handleSubmit, reset, watch, formState, errors, ...props })
            : children}
        </Box>
      )}

      {extraContent}

      {isEditMode && (
        <Box>
          <Divider />
          <Box
            p={contentPadding > -1 ? contentPadding : p}
            pb={contentPadding > -1 ? contentPadding : p + 1}
            display="flex"
            justifyContent="flex-end"
          >
            {renderEditModeActions()}
          </Box>
        </Box>
      )}
    </Paper>
  )

  return (
    <Box mt={mt} mb={mb}>
      {isEditMode ? <form onSubmit={handleSubmit(handleSave)}>{renderContent()}</form> : renderContent()}
    </Box>
  )
}

SectionCard.defaultProps = {
  variant: 'h4',
  p: 2,
  mt: 0,
  mb: 3,
}

export default SectionCard
